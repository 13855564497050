<button *ngIf="params.backUrl" (click)="comeBack()" mat-icon-button color="basic">
    <mat-icon>arrow_back</mat-icon>
    Volver
</button>
<div style = "text-align: center">
    
    <br>

    <mat-card class="native-card">
        <mat-card-title>{{user.nombres}}</mat-card-title>
        <br>
        <mat-card-content>
            <p>
                <mat-form-field>
                    <input type="text" value="{{user.cedula}}" matInput readonly placeholder=Cédula>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <input type="text" value="{{user.fldcorrespondencia_nombre}}" matInput readonly placeholder=Correspondencia>
                </mat-form-field>
            </p>
        </mat-card-content>
    </mat-card>

    <ng-container>
        <form [formGroup] = "checkoutForm" (ngSubmit) = "onSubmit(checkoutForm.value)">
            <p>
                <mat-form-field>
                    <mat-select required placeholder = "Jefe aprobador" formControlName = "ruta">
                        <mat-option *ngFor = "let approvalBoss of approvalManagers" [value] = "approvalBoss.codigo_empleado">
                            {{ approvalBoss.nombres }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf = "checkoutForm.get('ruta').invalid">
                        {{ getErrorMessage(checkoutForm.get('ruta')) }}
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field>
                    <mat-select required placeholder = "Actividad" (selectionChange)="onChangeActivity()" formControlName = "activity">
                        <mat-option *ngFor = "let activity of activities" [value] = "activity.codigo_actividad">
                            {{ activity.descripcion }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf = "checkoutForm.get('activity').invalid">
                        {{ getErrorMessage(checkoutForm.get('activity')) }}
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field>
                    <mat-select required placeholder = "Costo de objeto" (selectionChange)="onChangeCostObject()" formControlName = "costObject">
                        <mat-option *ngFor = "let costObject of costObjects" [value] = "costObject.codigo_objeto_costos">
                            {{ costObject.descripcion }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf = "checkoutForm.get('costObject').invalid">
                        {{ getErrorMessage(checkoutForm.get('costObject')) }}
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <ng-container *ngIf="serviceClients.length > 0">
                    <mat-form-field>
                        <mat-select placeholder = "Servicio de cliente" (selectionChange)="onChangeServiceClient()" formControlName = "serviceClient">
                            <mat-option *ngFor = "let serviceClient of serviceClients" [value] = "serviceClient.codigo">
                                {{ serviceClient.descripcion }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf = "checkoutForm.get('serviceClient').invalid">
                            {{ getErrorMessage(checkoutForm.get('serviceClient')) }}
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </p>

            <p>
                <mat-progress-spinner *ngIf = "showSpinner" color = "warn" mode = "indeterminate"></mat-progress-spinner>
            </p>
            
            <p>
                <button [disabled] = "!checkoutForm.valid || !enbaleButton" mat-raised-button color = "warn" type = "submit">
                    Enviar
                    <mat-icon>sync</mat-icon>
                </button>
            </p>
        </form>
    </ng-container>
</div>