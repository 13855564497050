import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
import { PagenotfoundComponent } from './pagenotfound.component';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [ PagenotfoundComponent ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],exports:[
    PagenotfoundComponent,
  ]
})

export class PagenotfoundModule { }