import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { TramiteService } from 'src/app/services/tramite.service';
import { User } from 'src/app/models/user';
import { Activity } from 'src/app/models/activity';
import { CostObject } from 'src/app/models/cost-object';
import { CostCenter } from 'src/app/models/cost-center';
import { ServiceClient } from 'src/app/models/service-client';
import { Parameters } from 'src/app/models/parameters';

@Component({
  selector: 'app-transition',
  templateUrl: './transition.component.html',
  styleUrls: ['./transition.component.css']
})

export class TransitionComponent implements OnInit {

  public checkoutForm: UntypedFormGroup;
  public showSpinner: Boolean;
  public enbaleButton: boolean = true;
  public user: User = { cedula: '', ciudad: '', codigo_cargo: '', codigo_categoria: '', codigo_correspondencia: '', codigo_dependencia: '', codigo_empleado: '', codigo_transporte: '', correo: '', fldcorrespondencia_nombre: '', login: '', nombre_dependencia: '', nombres: '' };
  public approvalManagers: User[];
  public activities: Activity[];
  public costObjects: CostObject[];
  private costCenter: CostCenter;
  public serviceClients: ServiceClient[] = [];
  public params: Parameters;

  constructor(private formBuilder: UntypedFormBuilder, private snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private userService: UserService, private tramiteService: TramiteService) { 
    this.checkoutForm = this.formBuilder.group({
      ruta: ['', [Validators.required]],
      activity: ['', [Validators.required]],
      costObject: ['', [Validators.required]],
      serviceClient: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    let data: string = this.route.snapshot.queryParamMap.get('data');
    
    if (data) {
      this.params = JSON.parse(atob(data));
      if (this.params.endpoint && this.params.code && this.params.conexion && this.params.apikey && this.params.tramiteId && this.params.transicionId && this.params.token && this.params.redirectUrl) {
        this.getUser(this.params.code);
        this.getApprovalBoss(this.params.code);
      }
      else {
        this.router.navigate(['/404']);
        this.showSnackBar('Faltan parametros en la solicitud');
      }
    }
    else {
      this.router.navigate(['/404']);
    }
  }

  private showSnackBar(message: string): void {
    let currentDate = new Date();
    this.snackBar.open(message + ' ' + currentDate.toLocaleString(), "OK", {
      duration: 5000,
    });
  }

  private getUser(code: string): void {
		this.userService.getUser(code, this.params.token).toPromise().then(response => {
			if (response.statusCode === 200 && response.payload) {
        this.user = response.payload;
        this.getActivities(this.user.codigo_dependencia, this.user.codigo_correspondencia, this.user.codigo_categoria, this.user.codigo_cargo);
      }
      else {
        this.showSnackBar('Ocurrio un inconveniente al realizar la petición para el usuario con código ' + code);
      }
		}, error => {
			this.showSnackBar('Ocurrio un inconveniente al realizar la petición para el usuario con código ' + code);
		});
  }

  private getApprovalBoss(code: string): void {
		this.userService.getApprovalBoss(code, this.params.token).toPromise().then(response => {
			if (response.statusCode === 200) {
        this.approvalManagers = response.payload;
      }
      else {
        this.showSnackBar('Ocurrio un inconveniente al realizar la petición jefes aprobadores para el usuario con código ' + code);
      }
		}, error => {
			this.showSnackBar('Ocurrio un inconveniente al realizar la petición jefes aprobadores para el usuario con código ' + code);
		});
  }

  private getActivity(code: string) {
    return this.activities.find( activity => activity.codigo_actividad === code );
  }

  private getActivities(dependency: string, correspondence: string, category: string, position: string): void {
    this.userService.getActivities(dependency, correspondence, category, position, this.params.token).toPromise().then(response => {
      if (response.statusCode === 200) {
        this.activities = response.payload;
      }
      else {
        this.showSnackBar('Ocurrio un inconveniente al realizar la petición de actividades para el usuario con código ' + this.user.codigo_empleado);
      }
		}, error => {
			this.showSnackBar('Ocurrio un inconveniente al realizar la petición de actividades para el usuario con código ' + this.user.codigo_empleado);
		});
  }

  private getCostObject(code: string) {
    return this.costObjects.find( costObject => costObject.codigo_objeto_costos === code );
  }

  private getCostObjects(dependency: string, correspondence: string, category: string, position: string, activity: string): void {
    this.userService.getCostObjects(dependency, correspondence, category, position, activity, this.params.token).toPromise().then(response => {
      if (response.statusCode === 200) {
        this.costObjects = response.payload;
      }
      else {
        this.showSnackBar('Ocurrio un inconveniente al realizar la petición costos de objeto para el usuario con código ' + this.user.codigo_empleado);
      }
		}, error => {
			this.showSnackBar('Ocurrio un inconveniente al realizar la petición costos de objeto para el usuario con código ' + this.user.codigo_empleado);
		});
  }

  private getCostCenter(serviceClient: string, activity: string): void {
    this.userService.getCostCenter(serviceClient, activity, this.user.cedula, this.params.token).toPromise().then(response => {
      this.changeSpinnerState();
      this.enbaleButton = true;
      if (response.statusCode === 200) {
        this.costCenter = response.payload;
      }
      else {
        this.showSnackBar('Ocurrio un inconveniente al realizar la petición centro de costos para el usuario con código ' + this.user.codigo_empleado);
      }
		}, error => {
      this.changeSpinnerState();
      this.enbaleButton = true;
			this.showSnackBar('Ocurrio un inconveniente al realizar la petición centro de costos para el usuario con código ' + this.user.codigo_empleado);
		});
  }

  private getServiceClient(code: string) {
    return this.serviceClients.find( serviceClient => serviceClient.codigo === code );
  }

  private getServiceClients(dependency: string, correspondence: string, category: string, position: string, activity: string, dedicationType: string): void {
    this.userService.getServiceClients(dependency, correspondence, category, position, activity, dedicationType, this.params.token).toPromise().then(response => {
      if (response.statusCode === 200) {
        this.serviceClients = response.payload;

        if (this.serviceClients.length > 0) {
          this.checkoutForm.get('serviceClient').setValidators([Validators.required]);
          this.checkoutForm.get('serviceClient').updateValueAndValidity();
        }
        else {
          this.checkoutForm.get('serviceClient').clearValidators();
          this.checkoutForm.get('serviceClient').updateValueAndValidity();
        }

      }
      else {
        this.serviceClients = [];
        this.checkoutForm.get('serviceClient')?.clearValidators();
        this.checkoutForm.get('serviceClient')?.updateValueAndValidity();
        this.showSnackBar('No se encontraron servicios para el objeto de costo');
      }
		}, error => {
      this.serviceClients = [];
      this.checkoutForm.get('serviceClient')?.clearValidators();
      this.checkoutForm.get('serviceClient')?.updateValueAndValidity();
			this.showSnackBar('No se encontraron servicios para el objeto de costo');
		});
  }

  private applyTransition(transitionData: object): void {
    this.tramiteService.applyTransition(this.params.endpoint, this.params.conexion, this.params.token, this.params.apikey, this.params.tramiteId, this.params.hash, this.params.hashTransicion, this.params.transicionId, transitionData).toPromise().then(response => {
      this.changeSpinnerState();
      if (response.status === 'ok') {
        window.location.href = this.params.redirectUrl;
      }
      else {
        this.showSnackBar(response.message);
      }
		}, error => {
      this.changeSpinnerState();
			this.showSnackBar('Ocurrio un inconveniente al aplicar la transición externa sobre el tramite');
		});
  }

  private changeSpinnerState(): void {
    this.showSpinner = !this.showSpinner;
  }

  public onChangeActivity(): void {
    this.getCostObjects(this.user.codigo_dependencia, this.user.codigo_correspondencia, this.user.codigo_categoria, this.user.codigo_cargo, this.checkoutForm.get('activity').value);
    this.checkoutForm.get('costObject').setValue('');
    this.checkoutForm.get('serviceClient').setValue('');
  }

  public onChangeCostObject(): void {
    this.getServiceClients(this.user.codigo_dependencia, this.user.codigo_correspondencia, this.user.codigo_categoria, this.user.codigo_cargo, this.checkoutForm.get('activity').value, this.getCostObject(this.checkoutForm.get('costObject').value).tipo_dedicacion);
    this.checkoutForm.get('serviceClient').setValue('');
  }

  public onChangeServiceClient(): void {
    this.changeSpinnerState();
    this.enbaleButton = false;
    this.getCostCenter(this.checkoutForm.get('serviceClient').value, this.checkoutForm.get('activity').value);
  }

  private addAditionalInformation(transitionData: Object) {
    let costObject: CostObject = this.getCostObject(this.checkoutForm.get('costObject').value);
    let serviceClient: ServiceClient = this.getServiceClient(this.checkoutForm.get('serviceClient').value);
    
    if (this.costCenter) {
      transitionData['costCenter'] = this.costCenter.centrodecostos;
      transitionData['typeCostCenter'] = this.costCenter.tipoob;
    }
    else {
      transitionData['costCenter'] = costObject.codigo_dependencia;
    }

    transitionData['dedicationType'] = costObject.tipo_dedicacion;
    transitionData['costObjectName'] = costObject.descripcion;
    transitionData['activityName'] = this.getActivity(this.checkoutForm.get('activity').value).descripcion;
    
    if (serviceClient) {
      transitionData['serviceClientName'] = serviceClient.descripcion;
    }
  }

  public onSubmit(transitionData): void {
    this.changeSpinnerState();
    this.addAditionalInformation(transitionData);
    this.applyTransition(transitionData);
  }

  public comeBack() {
    if (this.params.backUrl) {
      window.location.href = this.params.backUrl;
    }
  }

  public getErrorMessage(customerData) {
    return customerData.hasError('required') ? 'Campo requerido' :
      '';
  }

}