import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseApplyTransition } from '../models/response-apply-transition';

@Injectable({
  providedIn: 'root'
})

export class TramiteService {

  constructor(private http: HttpClient) { }

  public applyTransition(endpoint, connection: string, token: string, apikey: string, tramiteId: string, hash: string, hashTransicion: string, transicionId: string,  transitionData: Object): Observable<ResponseApplyTransition> {
    
    let headers = {
      headers: new HttpHeaders().set('conexion', connection)
                                .set('Authorization', 'Bearer ' + token)
    };

    /*if (codigoTramite) {
      transitionData['codigo'] = codigoTramite;
    }*/
    if (hash) {
      transitionData['hash'] = hash;
    }
    if (hashTransicion) {
      transitionData['hashTransicion'] = hashTransicion;
    }

    transitionData['apikey'] = apikey;
    transitionData['tramiteId'] = tramiteId;
    transitionData['transicionId'] = transicionId;

    return this.http.post<ResponseApplyTransition>(endpoint, transitionData, headers);
  }

}