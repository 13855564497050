import { Component, NgZone } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent {
  options: AnimationOptions = {
		path: '/assets/notfound.json'
	};

	styles: Partial<CSSStyleDeclaration> = {
		width: '100%',
		height: '100%',
		margin: '0 auto'
	};

	private animatiomItem: AnimationItem;

	constructor(private ngZone: NgZone) { }

	animationCreated(animationItem: AnimationItem): void {
		this.animatiomItem = animationItem;
	}

	stop(): void {
		this.ngZone.runOutsideAngular(() => this.animatiomItem.stop());
	}

	play(): void {
		this.ngZone.runOutsideAngular(() => this.animatiomItem.play());
	}
}