import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResponseUser } from '../models/response-user';
import { ResponseApprovalBoss } from '../models/response-approval-boss';
import { ResponseActivity } from '../models/response-activity';
import { ResponseCostObject } from '../models/response-cost-object';
import { ResponseServiceClient } from '../models/response-service-client';
import * as crypto from 'crypto-js';
import { ResponseCostCenter } from '../models/response-cost-center';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private methodUser: string = '/user';
  private methodApprovalBoss: string = '/bosses';
  private methodActivities: string = '/activities';
  private methodCostObjects: string = '/costObjects';
  private methodCostCenter: string = '/report';
  private methodServiceClients: string = '/services';

  constructor(private http: HttpClient) { }

  public getUser(code: string, token: string): Observable<ResponseUser> {
    let amzDate = new Date().toISOString().replace(/-|:|\..{3}/g, '');

    let headers = {
      headers: new HttpHeaders().set('code', code)
                                .set('Authorization', 'Bearer ' + token)
                                .set("X-Amz-date", amzDate)
                                //.set('Authorization', this.getAwsSignature(amzDate, this.methodUser))
    };

    return this.http.get<ResponseUser>(environment.endpoint + this.methodUser, headers);
  }

  public getApprovalBoss(code: string, token: string): Observable<ResponseApprovalBoss> {
    let amzDate = new Date().toISOString().replace(/-|:|\..{3}/g, '');

    let headers = {
      headers: new HttpHeaders().set('code', code)
                                .set('Authorization', 'Bearer ' + token)
                                .set("X-Amz-date", amzDate)
                                //.set('Authorization', this.getAwsSignature(amzDate, this.methodApprovalBoss))
    };

    return this.http.get<ResponseApprovalBoss>(environment.endpoint + this.methodApprovalBoss, headers);
  }

  public getActivities(dependency: string, correspondence: string, category: string, position: string, token: string): Observable<ResponseActivity> {
    let amzDate = new Date().toISOString().replace(/-|:|\..{3}/g, '');

    let headers = {
      headers: new HttpHeaders().set('dependency', dependency)
                                .set('correspondence', correspondence)
                                .set('category', category)
                                .set('position', position)
                                .set('Authorization', 'Bearer ' + token)
                                .set("X-Amz-date", amzDate)
                                //.set('Authorization', this.getAwsSignature(amzDate, this.methodActivities))
    };

    return this.http.get<ResponseActivity>(environment.endpoint + this.methodActivities, headers);
  }

  public getCostObjects(dependency: string, correspondence: string, category: string, position: string, activity: string, token: string): Observable<ResponseCostObject> {
    let amzDate = new Date().toISOString().replace(/-|:|\..{3}/g, '');

    let headers = {
      headers: new HttpHeaders().set('dependency', dependency)
                                .set('correspondence', correspondence)
                                .set('category', category)
                                .set('position', position)
                                .set('activity', activity)
                                .set('Authorization', 'Bearer ' + token)
                                .set("X-Amz-date", amzDate)
                                //.set('Authorization', this.getAwsSignature(amzDate, this.methodCostObjects))
    };

    return this.http.get<ResponseCostObject>(environment.endpoint + this.methodCostObjects, headers);
  }

  public getCostCenter(objectCost: string, activity: string, identification: string, token: string): Observable<ResponseCostCenter> {
    let amzDate = new Date().toISOString().replace(/-|:|\..{3}/g, '');

    let headers = {
      headers: new HttpHeaders().set('cost_object', objectCost)
                                .set('activity', activity)
                                .set('identification', identification)
                                .set('Authorization', 'Bearer ' + token)
                                .set("X-Amz-date", amzDate)
                                //.set('Authorization', this.getAwsSignature(amzDate, this.methodCostCenter))
    };

    return this.http.get<ResponseCostCenter>(environment.endpoint + this.methodCostCenter, headers);
  }

  public getServiceClients(dependency: string, correspondence: string, category: string, position: string, activity: string, dedicationType: string, token: string): Observable<ResponseServiceClient> {
    let amzDate = new Date().toISOString().replace(/-|:|\..{3}/g, '');

    let headers = {
      headers: new HttpHeaders().set('dependency', dependency)
                                .set('correspondence', correspondence)
                                .set('category', category)
                                .set('position', position)
                                .set('activity', activity)
                                .set('dedication_type', dedicationType)
                                .set('Authorization', 'Bearer ' + token)
                                .set("X-Amz-date", amzDate)
                                //.set('Authorization', this.getAwsSignature(amzDate, this.methodServiceClients))
    };

    return this.http.get<ResponseServiceClient>(environment.endpoint + this.methodServiceClients, headers);
  }

  private getAwsSignature(amzDate: string, method: string): string {
    let algorithm = 'AWS4-HMAC-SHA256';
    let signedHeaders = 'content-type;host;x-amz-date';
    let accessKey = 'AKIASYLJK54T3TPTBXUC';
    let secretKey = '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';
    let region = 'us-east-1';
    let service = 'execute-api';
    let datestamp = amzDate.substr(0, 8);

    let credentialScope = datestamp + '/' + region + '/' + service + '/' + 'aws4_request';

    let canonicalRequest = this.getCanonicalRequest(amzDate, method, signedHeaders);
    
    let stringToSign = this.getStringToSign(amzDate, credentialScope, canonicalRequest);

    let signingKey = this.getSignatureKey(secretKey, datestamp, region, service);

    let signature = crypto.HmacSHA256(stringToSign, signingKey).toString();

    let authorizationHeader = algorithm + ' ' + 'Credential=' + accessKey + '/' + credentialScope + ', ' +  'SignedHeaders=' + signedHeaders + ', ' + 'Signature=' + signature;

    return authorizationHeader
  }

  private getCanonicalRequest(amzDate: string, mehotd: string, signedHeaders: string): string {
    let canonicalHeaders = 'content-type:' + '\n' + 'host:' + 'llahydbfag.execute-api.us-east-1.amazonaws.com' + '\n' + 'x-amz-date:' + amzDate + '\n';
    let payloadHash = crypto.SHA256('').toString();
    let canonicalRequest = 'GET' + '\n' + '/prod' + mehotd + '\n' + '' + '\n' + canonicalHeaders + '\n' + signedHeaders + '\n' + payloadHash;
    return canonicalRequest;
  }

  private getStringToSign(amzDate: string, credentialScope: string, canonicalRequest: string): string {
    let algorithm = 'AWS4-HMAC-SHA256';
    let stringToSign = algorithm + '\n' + amzDate + '\n' + credentialScope + '\n' + crypto.SHA256(canonicalRequest).toString();
    return stringToSign;
  }

  private getSignatureKey(key, dateStamp, regionName, serviceName) {
    let kDate = crypto.HmacSHA256(dateStamp, "AWS4" + key);
    let kRegion = crypto.HmacSHA256(regionName, kDate);
    let kService = crypto.HmacSHA256(serviceName, kRegion);
    let kSigning = crypto.HmacSHA256("aws4_request", kService);
    return kSigning;
  }
  
}