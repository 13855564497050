import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransitionComponent } from './modules/transition/transition.component';
import { PagenotfoundComponent } from './modules/pagenotfound/pagenotfound.component';

const routes: Routes = [
  { path: '', component: TransitionComponent },
  { path: '404', component: PagenotfoundComponent },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }